<template lang="pug">
div.mx-3
  v-card(width="800").mx-auto.mt-10
    v-card-title.headline.grey.lighten-2 Account Renewal
    div.text-center.mx-auto.my-15(v-if="loading == true")
      v-progress-circular.my-15(:size="70" :width="7" indeterminate color="primary")
    div(v-else-if="waiting==true").ma-7
      v-alert(
        border="left"
        icon="mdi-check"
        color="grey"
        text
        ) <strong>Waiting for Account Validation.</strong> The form has been submitted. Please send a renewal request email with your Form 5/5a attached using your UP Mail Account to <a href="mailto: cslib@science.upd.edu.ph">cslib@science.upd.edu.ph</a>. The Administrator will validate your account after reviewing your credentials. This process will take 24 hours. Please do not close this website without getting logged out. Thank you!
    div(v-else).pa-7.pb-0
      v-alert(
        border="left"
        icon="mdi-alert-circle"
        color="primary"
        text
        ) All students are required to renew their accounts every semester. Please be informed that we are only accepting renewal requests from <strong> enrolled UPD College of Science (CS) Students, CS Faculty </strong> and <strong> students from other colleges with CS subjects.</strong> Thank you.
      div
        h6 College Unit
        v-autocomplete(
          :items="colleges"
          rounded
          filled
          dense
          v-model.trim="college"
          placeholder="Type and select college"
          :rules="[(v) => !!v || 'College is required. Please select one.']"
          ).mt-3
        div(v-if="college=='College of Science'")
          h6 Institute
          v-autocomplete(
            :items="institutes"
            rounded
            filled
            dense
            v-model.trim="institute"
            item-text="name"
            item-value="name"
            placeholder="Type and select institute"
            required
            :rules="[(v) => !!v || 'Institute is required. Please select one.']"
            ).mt-3
        h6 CS Courses Taking (e.g. Math 21, MS 101, Env. Sci. 1)
        v-list
          v-list-item(
          v-for="item, i in courses"
          :key="i"
          )
            v-list-item-icon
              v-icon mdi-arrow-right-bottom-bold
            v-list-item-action.text-muted.mr-1 Course {{i+1}}:
            v-list-item-content
              v-list-item-title
                v-chip {{item}}
            v-list-item-action
              v-btn(color="error" x-small @click="courses.splice(i, 1); entries=[]; course=''; lastCourse=''" :disabled="isLoading") remove
        v-text-field(
          v-model.trim="course"
          placeholder="Search Course"
          rounded
          filled
          dense
          @keyup.enter="search()"
          prepend-inner-icon="mdi-magnify"
          )
          template(v-slot:append)
            v-btn(
            color="primary"
            small
            rounded
            @click.stop="search()"
            ).mt-n1.mr-n3 Search
        div.caption.muted.mt-n5.text-center Please type the exact course code. This is case and space sensitive. Click among the course chips below to add the course.
        div.text-center
          div.my-3.subtitle-2 Search query should be atleast 4 characters. Click "Search" button or Enter to search.
          div.my-3 {{notify}}
          div(v-if="isLoading")
            v-progress-circular(
              indeterminate
              color="primary"
              ).mt-3
          span(v-for="entry, i in entries" :key="i" v-else)
            v-chip(
              class="ma-2"
              color="primary"
              outlined
              @click="addCourse(i, entry.course)"
            )
              v-icon(left) mdi-plus-circle
              span {{entry.course}}
    v-divider
    v-card-actions.px-7.pb-5(v-if="loading!=true")
      v-spacer
      v-btn(@click="logout()" small :loading="loading1" :disabled="loading") Logout
      v-btn(small color="primary" :disabled=" institution=='' || courses.length==0" @click="renewUser()" :loading="loading" v-if="waiting==false").ml-5 Submit
</template>

<script>
import colleges from "@/colleges.js";
import { db } from "../main";
import firebase from "firebase/app";
import "firebase/functions";
let renewApply = firebase.functions().httpsCallable("renewApply");
let getUser = firebase.functions().httpsCallable("getUser");
export default {
  data() {
    return {
      allCapsCourse: ["ms", "mse", "ge", "mbb"],
      loading1: false,
      college: "",
      institute: "",
      colleges: colleges.colleges,
      institutes: [
        "Institute of Biology",
        "Institute of Chemistry",
        "Institute of Environmental Science and Meteorology",
        "Institute of Mathematics",
        "Marine Science Institute",
        "Materials Science and Engineering Program",
        "National Institute of Geological Sciences",
        "National Institute of Molecular Biology and Biotechnology",
        "National Institute of Physics",
      ],
      course: "",
      courses: [],
      entries: [],
      isLoading: false,
      notify: "",
      lastCourse: "",
      loading: false,
      user: null,
      waiting: false,
    };
  },
  computed: {
    institution() {
      if (this.college == "College of Science") {
        return this.institute;
      }
      return this.college;
    },
    userId() {
      return this.$store.state.userToken.user_id;
    },
  },
  async created() {
    await this.getUser(this.userId);
  },
  methods: {
    async getUser(id) {
      this.loading = true;
      await getUser({ id }).then((res) => {
        if (res.data.user != null) {
          this.waiting = res.data.user.waiting;
        }
      });
      this.loading = false;
    },
    async search() {
      if (this.course.length < 4) return;
      if (this.lastCourse == this.course.toLowerCase()) return;
      this.lastCourse = this.course.toLowerCase();
      if (
        this.allCapsCourse.includes(this.course.split(" ")[0].toLowerCase())
      ) {
        const capitalAll = this.course.toUpperCase();
        await this.searchCourses(capitalAll);
      } else {
        const capitalFirst =
          this.course[0].toUpperCase() + this.course.substring(1);
        await this.searchCourses(capitalFirst);
      }
    },
    async searchCourses(query) {
      this.isLoading = true;
      const dataBase = await db
        .collection("courseReferences2")
        .orderBy("course")
        .startAt(query)
        .endAt(query + "\uf8ff")
        .limit(5);
      const dbResults = await dataBase.get();
      var entries = [];
      dbResults.forEach((doc) => {
        if (!this.courses.includes(doc.data().course)) {
          const data = {
            course: doc.data().course,
          };
          entries.push(data);
        }
      });
      this.isLoading = false;
      this.entries = entries;
      if (this.entries.length == 0) {
        this.notify = "No course found. Please enter the correct course code.";
        this.lastCourse = "";
      } else {
        this.notify = "";
      }
    },
    addCourse(i, course) {
      this.courses.push(course);
      this.entries.splice(i, 1);
      this.course = "";
      this.$store.dispatch("setSnackbar", {
        text: course + " added to your courses!",
        icon: "mdi-check",
        iconColor: "success",
      });
    },
    async renewUser() {
      if (this.institution == "" || this.courses.length == 0) {
        this.$store.dispatch("setSnackbar", {
          text: "  Please fill the required fields correctly!",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
        return;
      }
      this.loading = true;
      await renewApply({
        id: this.userId,
        institution: this.institution,
        courses: this.courses,
      }).then(() => {
        this.waiting = true;
      });
      this.$store.dispatch("setSnackbar", {
        text: " Request has been submitted",
        icon: "mdi-check",
        iconColor: "success",
      });
      this.loading = false;
    },
    logout() {
      this.loading1 = true;
      firebase.auth().signOut();
      this.loading1 = false;
    },
  },
};
</script>

<style></style>
